import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Routes, Route } from 'react-router-dom';
import { RegistrationPage } from './components/Root/RegistrationPage';

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);



