import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  ButtonGroup,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { UserIcon } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

// Styles for modal and positioning of buttons
const useStyles = makeStyles(theme => ({
  fab: {
    marginRight: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

export const RegistrationPage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message
  const [loading, setLoading] = useState(false);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), timeout),
      ),
    ]);
  };

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage(''); // Clear success message before new request

    // Validation logic for required fields
    if (!firstName || !lastName || !email ) {
      setErrorMessage('All fields are required');
      return;
    }

    // Validate email format (.mil or .edu)
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(mil|edu)$/;
    // if (!emailRegex.test(email)) {
    //   setErrorMessage('Email must end with .mil or .edu');
    //   return;
    // }



    try {
      setLoading(true);
      const d3dz3dBaseUrl = await discoveryApi.getBaseUrl('d3dz3d');

      const response = await fetchWithTimeout(`${d3dz3dBaseUrl}/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email }),
      });

      if (response.status === 201) {
        console.log(response);
        const successData = response.json();
        setSuccessMessage(successData.message || 'Successfully Sent Invitation!');
        setOpen(false); // Close the modal upon successful registration
        setEmail('');
        setFirstName('');
        setLastName('');

      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Registration failed');
      }
    } catch (error) {
      setErrorMessage('Something went wrong, please try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Modal for Registration Form */}
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Request Invitation to Join
            </Typography>
            <Box
              maxWidth={400}
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email (.mil or .edu)"
                type="email"
                variant="outlined"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                fullWidth
                FormHelperTextProps={{ required: true }}
                
                margin="normal"
              />

              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

              <Box display="flex" justifyContent="center" mt={2}>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  aria-label="contained primary button group"
                  fullWidth
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Register'}
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Buttons on the main page */}
      <Card>
        <CardHeader title="Request Platform Invitation" />
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Request an invitation to join the platform
          </Typography>
          <Box className={classes.buttonContainer}>
            {/* Register Button */}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpen}
              endIcon={<UserIcon />}
              className={classes.fab}
            >
              Request
            </Button>
          </Box>
          {/* Success message alert */}
          {successMessage && (
            <>
              <Alert severity="success">{successMessage}</Alert>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
