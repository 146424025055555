import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'd3dz3d',
});

export const environmentRouteRef = createRouteRef({
  id: 'environment-detail',
});

export const shredderRouteRef = createRouteRef({
  id: 'shredder-detail',
});
