import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import Dashboard from '@material-ui/icons/Dashboard';
import Difference from '@material-ui/icons/CompareArrows';

import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { Administration } from '@janus-idp/backstage-plugin-rbac';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarExpandButton,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Student from '@mui/icons-material/EmojiPeople';
import {
  AppsSharp,
  LibraryBooksOutlined,
  ListAltOutlined,
  ListAltTwoTone,
  ListRounded,
} from '@material-ui/icons';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
        {/* <img src="icons/logo.png" alt="Backstage" style={{ width: 175 }} /> */}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <Administration />
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="D3DZ3D" icon={<Dashboard />}>
        <SidebarItem icon={Dashboard} to="d3dz3d/" text="Environments" />
        <SidebarItem icon={Difference} to="d3dz3d/shredder" text="Shredder" />
        <SidebarItem icon={Student} to="/training" text="Training" />
        {/* <SidebarItem
          icon={LibraryBooksOutlined}
          to="d3dz3d/rancher"
          text="Environments II"
        />
        <SidebarItem
          icon={ListRounded}
          to="d3dz3d/workflows"
          text="Workflow Runs"
        /> */}
      </SidebarGroup>
      <SidebarDivider />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
        <SidebarExpandButton />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
