import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { AutoLogout } from '@backstage/core-components';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
//import { apis } from './apis';
import { oauth2ProxyAuthApiRef, apis } from './apis';
import { Link } from '@backstage/core-components';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  useApi,
  configApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInProviderConfig,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { createSpecializedApp } from '@backstage/core-app-api';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  D3Dz3DPage,
  D3Dz3DPageRancher,
  D3DZ3DShredder,
  D3Dz3DPageWorkflow,
  EnvironmentDetailPage,
  ShredderDetailPage,
} from '@internal/backstage-plugin-d3dz3d';
import { TrainingRouter } from '@internal/backstage-plugin-training';
// import { TodoListPage } from '@internal/plugin-todo-list';
import { RbacPage } from '@janus-idp/backstage-plugin-rbac';
import { RegistrationPage } from './components/Root/RegistrationPage';
import { Icon } from '@material-ui/core';


// const microsoftAuthProvider: SignInProviderConfig = {
//   id: 'azure-auth-provider',
//   title: 'Microsoft Active Directory',
//   message: 'Sign in to Backstage Application using your Active Directory account.',
//   apiRef: microsoftAuthApiRef,
// };

// const microsoftAuthProvider: SignInProviderConfig = {
//   id: 'azure-auth-provider',
//   title: 'Microsoft Active Directory',
//   message: 'Sign in to Backstage Application using your Active Directory account.',
//   apiRef: microsoftAuthApiRef,
// };
const CustomSignInPage = props => {
  const configApi = useApi(configApiRef);

  return (
    <div>
      {/* Conditionally render sign-in page based on environment */}
      {configApi.getString('auth.environment') === 'development' ? (
        <SignInPage
          {...props}
          title={<RegistrationPage/>}
          align='center'
          
          providers={[{
            id: 'azure-auth-provider',
            title: 'Microsoft Azure AD',
            message: 'Sign in using Azure AD',
            apiRef: microsoftAuthApiRef,
          }]}
        />
      ) : (
        <ProxiedSignInPage {...props} provider="oauth2Proxy" />
      )}

      {/* Add a link to the RegistrationPage */}
      
    </div>
  );
};



const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: CustomSignInPage,
  }
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/d3dz3d" element={<D3Dz3DPage />} />
    <Route path="/d3dz3d/rancher" element={<D3Dz3DPageRancher />} />
    <Route path="/d3dz3d/shredder" element={<D3DZ3DShredder />} />
    <Route
      path="/d3dz3d/shredder/:shredderId"
      element={<ShredderDetailPage />}
    />
<Route path="/training/*" element={<TrainingRouter />} /> {/* Mount TrainingRouter */}
    <Route path="/d3dz3d/workflows" element={<D3Dz3DPageWorkflow />} />
    <Route
      path="/d3dz3d/environments/:environmentName"
      element={<EnvironmentDetailPage />}
    />
    {/* <Route path="/todo-list" element={<TodoListPage />} /> */}
    <Route path="/rbac" element={<RbacPage />} />;
    <Route path="/registration" element={<RegistrationPage />} />

  </FlatRoutes>
);

export default app.createRoot(
  <>

    <AlertDisplay />
    {/* <OAuthRequestDialog /> */}
    <AutoLogout />
    <AppRouter>
      {/* Authorized Routes */}
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
