// plugins/training/src/api.ts

import { createApiRef } from '@backstage/core-plugin-api';
import { TrainingModule } from './types';

export interface TrainingApi {
  getModules(): Promise<TrainingModule[]>;
  getModule(id: string): Promise<TrainingModule>;
  createModule(module: Omit<TrainingModule, 'id' | 'created_at' | 'updated_at'>): Promise<TrainingModule>;
  updateModule(id: string, module: Partial<Omit<TrainingModule, 'id' | 'created_at' | 'updated_at'>>): Promise<TrainingModule>;
  uploadMedia(id: string, file: File): Promise<string>;
  deleteModule(id: string): Promise<void>;
  getUsers(): Promise<string[]>; // For user assignment
}

export const trainingApiRef = createApiRef<TrainingApi>({
  id: 'plugin.training.service',
});
