// plugins/training/src/plugin.ts

import {
  createPlugin,
  createRouteRef,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import { trainingApiRef } from './api';
import { createTrainingApi } from './apiClient';
import { TrainingRoutes } from './routes'; // Ensure this path is correct

// Define the root route reference
export const rootRouteRef = createRouteRef({
  id: 'training',
});

export const TrainingPlugin = createPlugin({
  id: 'training',
  apis: [
    createApiFactory({
      api: trainingApiRef,
      deps: {
        configApi: configApiRef,
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi }) =>
        createTrainingApi( discoveryApi, fetchApi, identityApi ),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
  // Other plugin configurations...
});

// Provide a single routable extension that handles all internal routing
export const TrainingRouter = TrainingPlugin.provide(
  createRoutableExtension({
    name: 'TrainingRouter',
    component: () => import('./routes').then((m) => m.TrainingRoutes),
    mountPoint: rootRouteRef,
  })
);
