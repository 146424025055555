import React, { useEffect, ComponentType, FC } from 'react';
import {
  createPlugin,
  createRoutableExtension,
  useApi,
} from '@backstage/core-plugin-api';
import { identityApiRef } from '@backstage/core-plugin-api';
import { rootRouteRef, environmentRouteRef, shredderRouteRef } from './routes';
import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel at the module level (runs once)
mixpanel.init('bcdb7bafea6514e8bd176a22e2b44204', {
  debug: false,
  track_pageview: 'full-url',
  persistence: 'localStorage',
});

// HOC to wrap components with Mixpanel tracking
function withMixpanelTracking<P>(WrappedComponent: ComponentType<P>): FC<P> {
  function WrappedWithTracking(props: P) {
    const identity = useApi(identityApiRef);

    useEffect(() => {
      const trackUser = async () => {
        const user = await identity.getProfileInfo();
        console.log('user', user);
        if (user) {
          mixpanel.identify(user.email); // Assuming email is the unique identifier
          mixpanel.people.set({
            $email: user.email,
            $name: user.displayName,
            $avatar: user.picture,
            $created: new Date().toISOString(),
          });
        }
      };
      trackUser();
    }, [identity]);

    // Explicitly return the wrapped component with props
    return React.createElement(WrappedComponent, { ...props });
  }

  return WrappedWithTracking;
}

export const d3Dz3DPlugin = createPlugin({
  id: 'd3dz3d',
  routes: {
    root: rootRouteRef,
  },
});

export const D3Dz3DPage = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'D3Dz3DPage',
    component: () =>
      import('./components/EnvironmentsComponent').then(m =>
        withMixpanelTracking(m.EnvironmentsComponent),
      ),
    mountPoint: rootRouteRef,
  }),
);

export const EnvironmentDetailPage = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'EnvironmentDetailPage',
    component: () =>
      import('./components/EnvironmentsComponent').then(m =>
        withMixpanelTracking(m.EnvironmentDetailComponent),
      ),
    mountPoint: environmentRouteRef,
  }),
);

export const D3DZ3DShredder = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'D3DZ3DShredder',
    component: () =>
      import('./components/ShredderComponent').then(m =>
        withMixpanelTracking(m.ShredderComponent),
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ShredderDetailPage = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'ShredderDetailPage',
    component: () =>
      import('./components/ShredderComponent').then(m =>
        withMixpanelTracking(m.ShredderDetailComponent),
      ),
    mountPoint: shredderRouteRef,
  }),
);

export const D3Dz3DPageSBOM = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'D3Dz3DPageSBOM',
    component: () =>
      import('./components/SbomBaseComponent').then(m =>
        withMixpanelTracking(m.SbomBaseComponent),
      ),
    mountPoint: rootRouteRef,
  }),
);

export const D3Dz3DPageRancher = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'D3Dz3DPageRancher',
    component: () =>
      import('./components/RancherEnvComponent').then(m =>
        withMixpanelTracking(m.RancherEnvironmentsComponent),
      ),
    mountPoint: rootRouteRef,
  }),
);

export const D3Dz3DPageWorkflow = d3Dz3DPlugin.provide(
  createRoutableExtension({
    name: 'D3Dz3DPageWorkflow',
    component: () =>
      import('./components/StatusPageComponent').then(m =>
        withMixpanelTracking(m.StatusPageComponent),
      ),
    mountPoint: rootRouteRef,
  }),
);
